.footer {
  position: relative;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  max-width: 70vmin; /* Limit width */
  width: 90%; /* Default width */
  height: 20vmin;
}
