/* fonts.css */
@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Bold.woff2") format("woff2"),
    url("./fonts/Satoshi-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Regular.woff2") format("woff2"),
    url("./fonts/Satoshi-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
