@import url("../fonts.css");

.projects-container {
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90vmin;
  margin: 0 auto;
  box-sizing: border-box;
}

.projects-title {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(2rem, 5vw, 3.5rem);
  color: #333;
  text-align: left;
  margin-bottom: 1rem;
}

.projects-description {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #666;
  text-align: left;
  margin-bottom: 5rem;
  padding: 0 1rem;
}

.project {
  display: flex;
  flex-direction: column; /* Change to column */
  align-items: center; /* Center horizontally */
  margin-bottom: 3rem;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
}

.project-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem; /* Adjust margin */
}

.project-img {
  height: 100%;
  max-height: 30vmin;
  height: auto;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.project-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center; /* Center text */
}

.project-title {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: bold;
  color: #333;
  text-align: center; /* Center text */
  margin-bottom: 2rem;
  padding: 0 1rem;
  text-align: left;
}

.project-description {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #666;
  text-align: center; /* Center text */
  margin-bottom: 2rem;
  padding: 0 1rem;
  text-align: left;
}

.github-link {
  color: black;
  width: 6vmin;
  height: 6vmin;
  transition: color 0.3s, transform 0.3s; /* Smooth transition for the effects */
}

.github-link:hover {
  /* color: #a91d3a; */
  transform: scale(1.1); /* Slightly increase the size on hover */
}

@media screen and (min-width: 768px) {
  .project {
    flex-direction: column; /* Change to row */
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Align items to the top */
  }

  .project-img {
    margin-left: 0.45rem;
    margin-right: 0.45rem;
  }
}
