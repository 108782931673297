@import url("../fonts.css");

.hello-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 75vmin;
  padding: 1rem;
  box-sizing: border-box;
}

.pfp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  margin-right: 2rem;
}

.hello {
  font-family: "Satoshi", sans-serif;
  text-align: left;
  margin-bottom: 1rem;
}

.type-animation {
  font-size: clamp(2rem, 5vw, 3.5rem); /* Responsive font size */
  background-image: url("../../public/bg1.gif");
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* color: #333; */
}

.description {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(1.2rem, 2.5vw, 1.6rem);
  color: #666;
  text-align: left;
  margin-bottom: 1rem;
}

.img {
  width: 25vmin;
  height: auto;
  border-radius: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.icons-container a {
  margin: 0 10px;
}

.icon {
  color: black;
  width: 6vmin;
  height: 6vmin;
  transition: color 0.3s, transform 0.3s; /* Smooth transition for the effects */
}

.icon:hover {
  /* color: #a91d3a;  */
  transform: scale(1.1); /* Slightly increase the size on hover */
}

.linkedin-icon {
  width: 5vmin; /* Adjust the size as needed */
  height: 5vmin;
}

/* Large tablets and small laptops (landscape mode) */
/* @media screen and (max-width: 1280px) {
  .hello-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pfp-container {
    width: 100%;
    align-items: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .description {
    text-align: center;
  }

  .icons-container {
    justify-content: center;
  }

  .img {
    width: 35vmin;
  }

  .icon {
    width: 7vmin;
    height: 7vmin;
  }

  .linkedin-icon {
    width: 6vmin; 
    height: 6vmin;
  }
} */

/* Tablet and small laptops (portrait mode) */
@media screen and (max-width: 1024px) {
  .hello-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pfp-container {
    width: 100%;
    align-items: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  /* .type-animation {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
  } */

  .description {
    text-align: center;
  }

  .icons-container {
    justify-content: center;
  }

  .img {
    width: 40vmin;
  }

  .icon {
    width: 8vmin;
    height: 8vmin;
  }

  .linkedin-icon {
    width: 7vmin; /* Adjust the size as needed */
    height: 7vmin;
  }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
  .hello-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pfp-container {
    width: 100%;
    align-items: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .description {
    text-align: center;
  }

  .icons-container {
    justify-content: center;
  }

  .img {
    width: 40vmin;
  }

  .icon {
    width: 8vmin;
    height: 8vmin;
  }

  .linkedin-icon {
    width: 7vmin; /* Adjust the size as needed */
    height: 7vmin;
  }
}
