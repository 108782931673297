/* About.css */
@import url("../fonts.css");
.about-container {
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80vmin;
  box-sizing: border-box;
  margin-bottom: -30vmin;
}

.about-title {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(2rem, 5vw, 3.5rem);
  color: #333;
  text-align: left;
  margin-bottom: 1rem;
}

.about-description {
  font-family: "Satoshi", sans-serif;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #666;
  text-align: left;
  margin-bottom: 5rem;
  padding: 0 1rem;
}

.red {
  color: #a91d3a;
}

.blender-images {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 2em;
}

.blender-img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.carousel-container {
  position: relative;
  max-width: 95%;
  margin: 0 auto;
}

.image-wrapper {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-image {
  width: 100%;
  max-height: 40vmin;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  user-select: none;
}

.carousel-button.left {
  left: 10px;
  border-radius: 0 4px 4px 0;
}

.carousel-button.right {
  right: 10px;
  border-radius: 4px 0 0 4px;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.indicator.active {
  background-color: rgba(255, 255, 255, 1);
}
